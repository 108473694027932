import './utils/vh.utils.js';
import './utils/accardion.utils.js';
import './utils/nameCut.utils.js';

import './components/header.component.js';
import './components/sliders.component.js';
import './components/map.component.js';
import './components/searchesAccordion.component.js';
import './components/detail.component.js';

import './libs/countUp.lib.js';
import './libs/aos.lib.js';


// popup FD
import scrollLock from 'scroll-lock';
import SimpleBar from 'simplebar';
import { countUpInit } from './libs/countUp.lib.js';

/*
var popupFD = document.querySelector('#popup');
if (popupFD) {
  var popupBlock = popupFD.querySelector('#popup-block');
 
  window.pushPopup = function pushPopup(parentBlock, classHelper = '') {
    if (classHelper) {
      const cls = classHelper.split(' ');
      popupBlock.classList.add(...cls);
    }
    

    setTimeout(() => {
      countUpInit()

      const customAccordion = (elements, trigger, openOne = false) => {
        if (openOne) {
            elements[0].classList.add('is-active');
    
            elements.forEach(accordion => {
                const triggerBtn = accordion.querySelector(trigger);
               
                triggerBtn.addEventListener('click', () => {
                    elements.forEach(accordion => {
                        accordion.classList.remove('is-active');
                    })
    
                    accordion.classList.add('is-active');
                })
            })
        } else {
            elements.forEach(accordion => {
                const triggerBtn = accordion.querySelector(trigger);
                triggerBtn.addEventListener('click', () => {
                    accordion.classList.toggle('is-active');
                })
            })
        }
    }

      const accordions = document.querySelectorAll('.js-accordion');
      customAccordion(accordions, '.js-accordion__head', true)
    }, 100)

    popupBlock.innerHTML = '';
    popupBlock.innerHTML = document.querySelector(parentBlock).innerHTML;

    document.querySelector('body').classList.add('overflow-hidden');
    popupFD.classList.add('active');
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    var scrollContentElement = document.querySelectorAll('.js-popup-scroll');
    if (scrollContentElement) {
        scrollContentElement.forEach(scrollWrap => {
            var simpleBarInstance = new SimpleBar(scrollWrap);
            simpleBarInstance.recalculate();
        })
    }
  };

  window.closePopup = function closePopup() {
    document.querySelector('#popup').classList.remove('active');
    popupBlock.className = 'popup__block';
    scrollLock.clearQueueScrollLocks();
    scrollLock.enablePageScroll();
    document.querySelector('body').classList.remove('overflow-hidden');
  }
}
*/

if (document.querySelector('[data-splitting]')) {
  const splittingArr = document.querySelectorAll('[data-splitting]');
  splittingArr.forEach((item, i) => {
    if (item.hasAttribute('data-splitting-char') === true) {
      const splitting = Splitting({
        target: item,
      });
    } else {
      const splitting = Splitting({
        target: item,
        by: 'words',
      });


    }
  });
}