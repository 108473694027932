function nameCut() {
    const upload = document.querySelector('.js-upload-input');
    const uploadLabel = document.querySelector('.js-upload-text');

    if (upload && uploadLabel) {
        upload.addEventListener('change', function () {
            let dots;
            const arr = this.files[0].name.split('.');
            arr[0].length > 6 ? (dots = '...') : (dots = '.');
            const name = arr[0].substring(0, 10) + dots + arr[1];
            uploadLabel.innerHTML = name;
        });
    }
}
nameCut();