import { CountUp } from 'countup.js';

export const countUpInit = () => {
  let countUpArr = new Array();

  function CountUpFunction(id, finalNum, option = {}) {
    let defaultOptions = {
      separator: "",
      enableScrollSpy: true,
      scrollSpyOnce: true,
    };

    let mergeOptions = { ...defaultOptions, ...option };
    countUpArr.push(new CountUp(id, finalNum, mergeOptions));
  }

  const countUp = (elements) => {
    elements.forEach((counter, i) => {
      const value = counter.getAttribute("data-count");
      CountUpFunction(counter, value, { suffix: "" });
    });
  }

  if (document.querySelector('.js-count')) {
    const counters = document.querySelectorAll('.js-count');
    countUp(counters);
  }
}

countUpInit()

/*
window.jsCountInit = function jsCountInit() {
  countUpInit()
}*/
