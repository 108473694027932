import Swiper, { Autoplay, Navigation} from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.js';
import { Thumbs } from 'swiper';
import { EffectFade } from 'swiper';
import { FreeMode } from 'swiper';
if (document.querySelector('.approach__slider')) {
	const swiper = new Swiper('.approach__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
		spaceBetween: 25,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".approach .slider-btn.prev",
            nextEl: ".approach .slider-btn.next",
		},
        breakpoints: {
            600: {
                slidesPerView: 2,
            },
            769: {
                slidesPerView: 1,
                spaceBetween: 30,
            },
        },
	});
}
if (document.querySelector('.jobs__slider')) {
	const swiper = new Swiper('.jobs__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
		spaceBetween: 25,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".jobs .slider-btn.prev",
            nextEl: ".jobs .slider-btn.next",
		},
        breakpoints: {
            658: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
        },
	});
}
if (document.querySelector('.reviews__slider')) {
	const swiper = new Swiper('.reviews__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
		spaceBetween: 25,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".reviews .slider-btn.prev",
            nextEl: ".reviews .slider-btn.next",
		},
        breakpoints: {
            568: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
	});
}
if (document.querySelector('.testimonials__slider')) {
	const swiper = new Swiper('.testimonials__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
		spaceBetween: 25,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".testimonials .slider-btn.prev",
            nextEl: ".testimonials .slider-btn.next",
		},
        breakpoints: {
            658: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
        },
	});
}
if (document.querySelector('.cases__slider')) {
	const swiper = new Swiper('.cases__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
		spaceBetween: 25,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".cases .slider-btn.prev",
            nextEl: ".cases .slider-btn.next",
		},
        breakpoints: {
            658: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
	});
}
if (document.querySelector('.disciplines__slider')) {
	const swiper = new Swiper('.disciplines__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
		spaceBetween: 15,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".disciplines .slider-btn.prev",
            nextEl: ".disciplines .slider-btn.next",
		},
        breakpoints: {
            900: {
                spaceBetween: 22,
            },
        },
	});
}
if (document.querySelector('.advantages__slider')) {
	const swiper = new Swiper('.advantages__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
		spaceBetween: 25,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".advantages .slider-btn.prev",
            nextEl: ".advantages .slider-btn.next",
		},
        breakpoints: {
            658: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 32,
            },
        },
	});
}
if (document.querySelector('.clients__slider')) {
    const slidersWrappers = document.querySelectorAll('.clients__slider-box'); 

    slidersWrappers.forEach(wrapper => {
        const slider = wrapper.querySelector('.clients__slider');
        const btnPrev = wrapper.querySelector('.slider-btn.prev');
        const btnNext = wrapper.querySelector('.slider-btn.next');

        const swiper = new Swiper(slider, {
            modules: [Navigation, Autoplay],
            slidesPerView: 3,
            spaceBetween: 16,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                prevEl: btnPrev,
                nextEl: btnNext,
            },
            breakpoints: {
                1024: {
                    spaceBetween: 30,
                },
            },
        });
    })
	
}


if (document.querySelector('.values__slider')) {
    swiperOnResize('(max-width: 1023px)', '.values__slider', {
      modules: [Navigation, Autoplay],
      slidesPerView: 1,
      spaceBetween: 16,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: '.values .slider-btn.prev',
        nextEl: '.values .slider-btn.next',
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
      },
    });
  }
if (document.querySelector('.jobs__grid-slider')) {
    swiperOnResize('(max-width: 1023px)', '.jobs__grid-slider', {
      modules: [Navigation, Autoplay],
      slidesPerView: 1,
      spaceBetween: 16,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: '.jobs .slider-btn.prev',
        nextEl: '.jobs .slider-btn.next',
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        900: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }


  if (document.querySelector('.sectors')) {
    const thumbSlider = new Swiper(".sectors__thumb", {
        modules: [FreeMode, Navigation, Thumbs],
        spaceBetween: 25,
        slidesPerView: 2,
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
            450: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            600: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            900: {
                slidesPerView: 5,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 'auto',
                spaceBetween: 68,
            },
        }
    });

    const mainSlider = new Swiper(".sectors__slider", {
        modules: [FreeMode, Navigation, Autoplay, Thumbs, EffectFade],
        spaceBetween: 10,
        effect: "fade",
        speed: 500,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: '.sectors .slider-btn.prev',
            nextEl: '.sectors .slider-btn.next',
          },
        thumbs: {
            swiper: thumbSlider,
        },
    });

    thumbSlider.el.addEventListener('mouseenter', () => {
      mainSlider.autoplay.stop();
    });

    thumbSlider.el.addEventListener('mouseleave', () => {
      mainSlider.autoplay.start();
    });

    mainSlider.el.addEventListener('mouseenter', () => {
      mainSlider.autoplay.stop();
    });

    mainSlider.el.addEventListener('mouseleave', () => {
      mainSlider.autoplay.start();
    });
}