const customAccordion = (elements, trigger, openOne = false) => {
    if (openOne) {
        elements[0].classList.add('is-active');

        elements.forEach((accordion, idx) => {
            const triggerBtn = accordion.querySelector(trigger);

            triggerBtn.addEventListener('click', () => {
                elements.forEach(accordion => {
                    accordion.classList.remove('is-active');
                });

                accordion.classList.add('is-active');

                if(triggerBtn.classList.contains('offers__head')) {
                    const triggerBtnRect = triggerBtn.getBoundingClientRect();

                    if (triggerBtnRect.top <= 100) {
                        setTimeout(function () {
                            scrollToEl(`#accordion-${idx + 1}|300|${window.innerWidth >= 768 ? 180 : 100}`);
                            console.log(1)
                        }, 400);
                    } else if (triggerBtn.classList.contains('offers__head')) {
                        setTimeout(function () {
                            scrollToEl(`#accordion-${idx + 1}|300|${window.innerWidth >= 768 ? 180 : 100}`);
                            console.log(2)
                        }, 400);
                    }
                }
            });
        });
    } else {
        elements.forEach(accordion => {
            const triggerBtn = accordion.querySelector(trigger);
            triggerBtn.addEventListener('click', () => {
                accordion.classList.toggle('is-active');
            });
        });
    }
}

if (document.querySelector(".offers")) {
    const accordions = document.querySelectorAll('.js-accordion');
    customAccordion(accordions, '.js-accordion__head', true)
}